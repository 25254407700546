import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import { FaCheckCircle, FaThumbsDown } from "react-icons/fa"
import { HTMLContent } from "../components/Content"
import { MdPhone } from "react-icons/md"

// To format the phone number
import NumberFormat from 'react-number-format';

// For CSV download
import { CSVLink } from "react-csv";

const ListAll = ({ data }) => {

  // Create a new const for csvData, and manually add the "headers" (first row)
  const csvData = [
    ["Name", "Email", "Direct Line", "Role", "Team", "Discipline", "Slack User ID"]
  ];
  // Loop through the DatoCMS data
  // Set a new const array for each individual contactData
  {
    data.allcontacts.edges.forEach(contact => {
      const contactData = [
        contact.node.name,
        contact.node.email,
        contact.node.directLine,
        contact.node.role,
        contact.node.team?.title,
        contact.node.discipline?.title,
        contact.node.slackUserId
      ];
      // Push this new const array into csvData
      csvData.push(contactData)
    })
  };
  return (
    <>
      <Seo title="All Contacts" />

      <div className="p-8 pb-16">

        <div className="flex flex-row items-center gap-6 mb-8">

          <p className="font-display text-2xs">Total Contacts: {data.allcontacts.edges.length}</p>

          <CSVLink className="p-2 border border-current opacity-25 text-3xs hover:opacity-100" filename={"adtrak-all-contacts.csv"} data={csvData}>Download as CSV</CSVLink>

        </div>

        <table className="relative w-full text-left text-2xs">
          <tr className="sticky top-0 bg-white dark:bg-secondary-medium">
            <th className="pt-10">Name</th>
            <th className="pt-10">Job Title</th>
            <th className="pt-10"><MdPhone /></th>
            <th className="pt-10">Team</th>
            <th className="pt-10 text-center">Mngr/Asst?</th>
            <th className="pt-10 text-center">HoD?</th>
            <th colSpan={5} className="relative px-6 pt-10 rounded-tl-xl rounded-tr-xl 3xl:rounded-tl-full 3xl:rounded-tr-full bg-secondary-default bg-opacity-10">
              <span className="absolute inset-0 bg-white dark:bg-secondary-medium -z-10"></span>
              <span className="absolute top-0 mt-1 transform -translate-x-1/2 opacity-75 left-1/2 whitespace-nowrap text-2xs">Working Days</span>
              <div className="flex flex-row justify-between gap-6">
                <span className="text-center">Mo</span>
                <span className="text-center">Tu</span>
                <span className="text-center">We</span>
                <span className="text-center">Th</span>
                <span className="text-center">Fr</span>
              </div>
            </th>
            <th className="pt-10 pl-2">Rough Working Hours</th>
            <th className="pt-10">Line Manager</th>
            <th className="pt-10">About/Bio</th>
          </tr>
          {data.allcontacts.edges.map((contact, ckey) => (
            <tr key={ckey}>
              <td className="p-1 border border-secondary-default border-opacity-20">{contact.node.name}</td>
              <td className="p-1 border border-secondary-default border-opacity-20">{contact.node.role}</td>
              <td className="p-1 border border-secondary-default border-opacity-20"><a className="hover:opacity-50" href={`tel:${contact.node.directLine}`}><NumberFormat format={'#### ### ####'} displayType={'text'} value={contact.node.directLine} /></a></td>
              <td className="p-1 border border-secondary-default border-opacity-20">{contact.node.team.title}</td>
              <td className="p-1 text-center border border-secondary-default border-opacity-20">{contact.node.manager ? <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" /> : null}</td>
              <td className="p-1 text-center border border-secondary-default border-opacity-20">{contact.node.disciplineHead ? <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" /> : null}</td>
              <td className="p-1 text-center border bg-opacity-10 bg-secondary-default border-secondary-default border-opacity-20">{contact.node.monday ? <FaThumbsDown className="mx-auto text-primary-light" /> : <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" />}</td>
              <td className="p-1 text-center border bg-opacity-10 bg-secondary-default border-secondary-default border-opacity-20">{contact.node.tuesday ? <FaThumbsDown className="mx-auto text-primary-light" /> : <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" />}</td>
              <td className="p-1 text-center border bg-opacity-10 bg-secondary-default border-secondary-default border-opacity-20">{contact.node.wednesday ? <FaThumbsDown className="mx-auto text-primary-light" /> : <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" />}</td>
              <td className="p-1 text-center border bg-opacity-10 bg-secondary-default border-secondary-default border-opacity-20">{contact.node.thursday ? <FaThumbsDown className="mx-auto text-primary-light" /> : <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" />}</td>
              <td className="p-1 text-center border bg-opacity-10 bg-secondary-default border-secondary-default border-opacity-20">{contact.node.friday ? <FaThumbsDown className="mx-auto text-primary-light" /> : <FaCheckCircle className="mx-auto text-secondary-medium dark:text-secondary-light" />}</td>
              <td className="p-1 pl-2 border border-secondary-default border-opacity-20">{contact.node.workingHours}</td>
              <td className="p-1 border border-secondary-default border-opacity-20">{contact.node.lineManager?.name}</td>
              <td className="p-1 border border-secondary-default border-opacity-20 w-96"><HTMLContent content={contact.node.biography} /></td>
            </tr>
          ))}
        </table>
      </div>
    </>
  )
}

export default ListAll


// To re-order by team, add team___title (or equilavent), into the fields sort
export const query = graphql`
  query AllContacts {
    allcontacts: allDatoCmsContact(sort: { fields: [name] }) {
      edges {
        node {
          id
          team {
            id
            title
          }
          name
          email
          directLine
          role
          manager
          disciplineHead
          lineManager {
            name
          }
          image {
            gatsbyImageData(width: 800, height: 800, placeholder: BLURRED)
          }
          discipline {
            title
          }
          monday
          tuesday
          wednesday
          thursday
          friday
          workingHours
          slackUserId
          biography
        }
      }
    }
  }
`
